$(window).on('load', function () {
    $('.responsive').each(function () {
        $(this).css({
            'width': 'calc(' + $(this).width() + 'em/16)'
        })
    });

    setTimeout(function () {
        $('#preloader').fadeOut();
        $('.main-wrapper').css({
            'max-height': 'none',
            'overflow-y': 'auto'
        });
    }, 1500);
});

$(document).ready(function () {
    $('.text-block .container').fadeTo(0, 0.0);
    setTimeout(function () {
        $('.text-block .container').height($('.text-block .container').outerHeight());
    }, 500);
    setTimeout(function () {
        $('.text-block .container').fadeTo(0, 1.0);
        var instance = new TypeIt('.text-block .container', {
            speed: 30,
            breakLines: false,
            nextStringDelay: 0,
            startDelay: 250,
            loopDelay: 750,
            loop: false,
            autoStart: false,
            lifeLike: false,
            html: true
        }).type();
    }, 1000);
});

$(window).scroll(function () {
    //Parallax effect on header image
    var top = $(this).scrollTop();
    $('.display-1').css({
        'transform': 'translateY(' + top / 2 + 'px)',
        'opacity': $(window).height() / (1.5 * top) - 1
    });

    //Add class .animation-on to classes .animation-block
    $('.animation-block').each(function () {
        var topPos;
        if ($(this).height() < $(window).height()) {
            topPos = $(this).offset().top - $(window).height() + $(this).height() / 2;
        }
        else {
            topPos = $(this).offset().top - 4 * $(window).height() / 5;
        }
        var top = $(window).scrollTop();
        if (top > topPos) $(this).addClass('animation-on');
    });

    //Mockups animation
    if ($('.display-7').length) {
        if ($(window).scrollTop() + $(window).height() > $('.display-7 .grey-bg').offset().top) {
            var a = $(window).scrollTop() + $(window).height() - $('.display-7 .grey-bg').offset().top;
            $('.display-7 .column:first-child, .display-7 .column:last-child').css('transform', 'translateY(' + a / 5 + 'px)');
            $('.display-7 .column:nth-child(2)').css('transform', 'translateY(-' + a / 5 + 'px)');
        }
    }

    //Mobile horizontal parallax
    if ($(window).width() < 480) {
        $('.mobile-wide:not(.display-1)').each(function () {
            var diff = ($(window).scrollTop() - $(this).offset().top + $(window).height() - $(this).height()) / ($(window).height() - $(this).height()) * 100;
            if ($(window).scrollTop() + $(window).height() - $(this).height() > $(this).offset().top && $(window).scrollTop() < $(this).offset().top ) {
                $(this).css('margin-left', '-' + diff + '%')
            }
            else if ($(window).scrollTop() + $(window).height() - $(this).height() < $(this).offset().top) {
                $(this).css('margin-left', '0%')
            }
            else if ($(window).scrollTop() > $(this).offset().top) {
                $(this).css('margin-left', '-100%')
            }
        });
    }

    //Display-10 animation
    if ($('.display-10').length) {
        $('.display-10 .animation-block > div:last-child').each(function () {
            if ($(window).scrollTop() + $(window).height() > $(this).offset().top && $(window).scrollTop() + $(window).height() < $(this).offset().top + $(this).height()) {
                var percentage = ($(window).scrollTop() + $(window).height() - $(this).offset().top)/$(this).height();
                if (($(this).parent().index()%2) == 0) {
                    $(this).css('transform', 'translateX(' + (1 - percentage)*100 + '%)');
                }
                else {
                    $(this).css('transform', 'translateX(-' + (1 - percentage)*100 + '%)');
                }
                $(this).siblings('div').find('img').css('opacity', percentage);
            }
            else if ($(window).scrollTop() + $(window).height() < $(this).offset().top) {
                if (($(this).parent().index()%2) == 0) {
                    $(this).css('transform', 'translateX(100%)');
                }
                else {
                    $(this).css('transform', 'translateX(-100%)');
                }
            }
            else if ($(window).scrollTop() + $(window).height() > $(this).offset().top + $(this).height()) {
                $(this).css('transform', 'translateX(0)');
            }
        })
    }
});

// Safari 3.0+ "[object HTMLElementConstructor]"
var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);

//Scroll speed and smooth
$(function () {
    if (isSafari == false) {
        // Custom Easing
        $.scrollSpeed(200, 800);
    }
});

// $(document).ready(function() {
//
//     var $el = $(".display-7 .column img"),
//         disC = 50,
//         blurC = 50;
//
//     function mid(a,b) {
//         return (Math.abs(a) + Math.abs(b)) / 2;
//     }
//
//     $(document).on("mousemove", function(e) {
//         var x = e.pageX,
//             y = e.pageY;
//         $el.each(function() {
//             var w = $(this).width(),
//                 h = $(this).height(),
//                 top = $(this).offset().top,
//                 left = $(this).offset().left,
//                 xDiff = (left + w/2) - x,
//                 yDiff = (top + h/2) - y;
//             $(this).css("box-shadow",
//                 xDiff/disC + "px "
//                 + yDiff/disC + "px "
//                 + mid(xDiff, yDiff)/blurC*4 + "px" + ' rgba(0,0,0,.2)');
//         });
//     });
//
//     e = $.Event('mousemove');
//     e.pageX = $(window).width()/2;
//     e.pageY = 0;
//     $(document).trigger(e);
//
// });